<template>
  <div>
    <navbar active="home" />
    <fullpage />
    <nav class="nav-end navbar hidden-xs">
      <div class="container clearfix">
        <!--banner container-->
        <div class="collapse navbar-collapse" id="mico-navbar-collapse-1">
          <ul class="nav navbar-nav">
            <li><a href="/terms/terms.html">Terms</a></li>
            <li class="line"><a href="#">|</a></li>
            <li><a href="/terms/privacy.html">Privacy</a></li>
            <!--<li class="line"><a href="#">|</a></li>-->
            <!--<li><a href="/about">About Mico</a></li>-->
          </ul>
          <ul v-if="false" class="nav navbar-nav navbar-right share_group">
            <li><a class="mico-width" href="https://www.facebook.com/micoapp"><img  src="/img/website/facebook.png" alt="Mico Facebook"/></a></li>
            <li><a class="mico-width" href="https://twitter.com/micoapp"><img src="/img/website/twitter.png" alt="Mico Twitter"/></a></li>
            <li><a class="mico-width" href="https://plus.google.com/104407370352973871981"><img src="/img/website/google.png" alt="Mico Google Plus"/></a></li>
          </ul>
        </div>
        <div style="margin-top: 10px; color: rgb(102, 102, 102); text-align: center;">
          Gmancal Studio.  © 2020<br>
          Address: Thiam Ruam Mit Rd, Huai Khwang, Bangkok 10310, Thailand
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
  import Navbar from '../../common/components/Navbar'
  import Fullpage from './Fullpage'
  export default {
    components: { Navbar, Fullpage },
    data() {
      return {}
    }
  }
</script>
<style lang="less">
@import "../css/index.less";
</style>
