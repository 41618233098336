var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar", { attrs: { active: "home" } }),
      _vm._v(" "),
      _c("fullpage"),
      _vm._v(" "),
      _c("nav", { staticClass: "nav-end navbar hidden-xs" }, [
        _c("div", { staticClass: "container clearfix" }, [
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "mico-navbar-collapse-1" },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              false
                ? _c(
                    "ul",
                    { staticClass: "nav navbar-nav navbar-right share_group" },
                    [_vm._m(1), _vm._v(" "), _vm._m(2), _vm._v(" "), _vm._m(3)]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "nav navbar-nav" }, [
      _c("li", [
        _c("a", { attrs: { href: "/terms/terms.html" } }, [_vm._v("Terms")]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "line" }, [
        _c("a", { attrs: { href: "#" } }, [_vm._v("|")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/terms/privacy.html" } }, [
          _vm._v("Privacy"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          staticClass: "mico-width",
          attrs: { href: "https://www.facebook.com/micoapp" },
        },
        [
          _c("img", {
            attrs: { src: "/img/website/facebook.png", alt: "Mico Facebook" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          staticClass: "mico-width",
          attrs: { href: "https://twitter.com/micoapp" },
        },
        [
          _c("img", {
            attrs: { src: "/img/website/twitter.png", alt: "Mico Twitter" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          staticClass: "mico-width",
          attrs: { href: "https://plus.google.com/104407370352973871981" },
        },
        [
          _c("img", {
            attrs: { src: "/img/website/google.png", alt: "Mico Google Plus" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          "margin-top": "10px",
          color: "rgb(102, 102, 102)",
          "text-align": "center",
        },
      },
      [
        _vm._v("\n        Gmancal Studio.  © 2020"),
        _c("br"),
        _vm._v(
          "\n        Address: Thiam Ruam Mit Rd, Huai Khwang, Bangkok 10310, Thailand\n      "
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }