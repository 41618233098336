var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar navbar-fixed-top", attrs: { role: "navigation" } },
    [
      _c("div", { staticClass: "container hidden-xs" }, [
        _c("div", { staticClass: "navbar-header" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "navbar-brand", attrs: { href: _vm.logoLink } },
            [
              _c("img", {
                staticClass: "logo",
                attrs: {
                  src: require("../../../assets/images/mika_logo.png"),
                  alt: "logo",
                },
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggle collapsed",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": ".navbar-collapse",
        },
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Toggle navigation")]),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
        _vm._v(" "),
        _c("span", { staticClass: "icon-bar" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container visible-xs-block mobile" }, [
      _c("div", { staticClass: "navbar-header" }, [
        _c(
          "button",
          {
            staticClass: "navbar-toggle collapsed",
            attrs: {
              type: "button",
              "data-toggle": "collapse",
              "data-target": ".navbar-collapse",
            },
          },
          [
            _c("span", { staticClass: "sr-only" }, [
              _vm._v("Toggle navigation"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "icon-bar" }),
            _vm._v(" "),
            _c("span", { staticClass: "icon-bar" }),
            _vm._v(" "),
            _c("span", { staticClass: "icon-bar" }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "collapse navbar-collapse" }, [
        _c("ul", { staticClass: "nav navbar-nav" }, [
          _c("li", [
            _c("a", { attrs: { href: "/terms/terms.html" } }, [
              _vm._v("Terms"),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "/terms/privacy.html" } }, [
              _vm._v("Privacy"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }