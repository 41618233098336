/**
 * Created by Sam on 2020/6/12.
 */

export default {
  en: {
    home: {
      titles: [
        "SEE WHO'S NEARBY",
        "SHORT VIDEOS",
        "LIVE STREAMING",
        "GROUP",
        "FREE CHAT"
      ],
      descs: [
        "Meet interesting people nearby.",
        "Find out the funniest and the coolest short videos!",
        "Watch and broadcast anytime and anywhere.",
        "Find a bunch of nearby people with the same interests.",
        "Chat with someone nearby!"
      ],
      images: [
        '//image.micoworld.net/01985c35e2642d4232d13bdd8610be07',
        '//cdn.micoworld.net/upload/fc5eb137a00e45678e0ffd7ae54ca32d.png',
        '//cdn.micoworld.net/upload/b99e10f2ca8b4bd2b434fecee81b2926.png',
        '//cdn.micoworld.net/upload/6340f3baa15349e388788393d42f442e.png',
        '//cdn.micoworld.net/upload/b9b3bd5218394e31a192dd88b456dab6.png'
      ]
    }
  }
}
