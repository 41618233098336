<template>
  <div class="navbar navbar-fixed-top" role="navigation">
    <div class="container hidden-xs">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target=".navbar-collapse">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" :href="logoLink">
          <img class="logo" src="../../../assets/images/mika_logo.png" alt="logo">
        </a>
      </div>
    </div>

    <div class="container visible-xs-block mobile">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target=".navbar-collapse">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse">
        <ul class="nav navbar-nav">
          <li><a href="/terms/terms.html">Terms</a></li>
          <li><a href="/terms/privacy.html">Privacy</a></li>
          <!--<li><a href="/about">About Mico</a></li>-->
        </ul>
      </div><!--/.nav-collapse -->
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      active: {
        type: String,
        default: ''
      }
    },
    data() {
      return {}
    },
    computed: {
      logoLink() {
        return ['live', 'hot'].includes(this.active) ? '/hot' : window.location.href
      }
    }
  }
</script>
<style lang="less" scoped>
@import "./index";
</style>
